<template>

<div style="max-width: 800px">

  <div class="card-box">
    <h4>Admin Page</h4>
    <p>Various resources for developing this admin page</p>
    <CButton @click="loadPath('developer-tools/admin-page')" color="info" class="card-box-button">
      Admin Page
    </CButton>
  </div>

  <div class="card-box">
    <h4>API Tester</h4>
    <p>Interface for testing your services APIs</p>
    <CButton @click="loadPath('developer-tools/api-tester')" color="info" class="card-box-button">
      API Tester
    </CButton>
  </div>

  <div class="card-box">
    <h4>Audio Fingerprint Tester</h4>
    <p>Tool for testing our audio fingerprinter</p>
    <CButton @click="loadPath('developer-tools/audio-fingerprint-tester')" color="info" class="card-box-button">
      Audio Fingerprint Tester
    </CButton>
  </div>

  <div class="card-box">
    <h4>Google Cloud Links</h4>
    <p>Links to various pages on GCP for our account: <b>nrcc-2017</b></p>
    <CButton @click="loadPath('developer-tools/google-cloud-links')" color="info" class="card-box-button">
      Google Cloud Links
    </CButton>
  </div>

  <div class="card-box">
    <h4>Ping</h4>
    <p>Tool for repeatedly pinging a URL and displaying the result</p>
    <CButton @click="loadPath('developer-tools/ping')" color="info" class="card-box-button">
      Ping
    </CButton>
  </div>

  <div class="card-box">
    <h4>Service Template</h4>
    <p>Create a new service using the service template</p>
    <CButton @click="loadPath('developer-tools/service-template')" color="info" class="card-box-button">
      Service Template
    </CButton>
  </div>

</div>
</template>

<script>
export default {
  name: 'Developer-Tools',
  methods: {
    //--------------------------------------------------------------------------
    loadPath(path) {
      this.$router.push({
        path: path
      });
    },
  }
}
</script>
